<template>
<div>
    <div v-if="getUser.role.slug === 'super-administrateur'">
        <div v-if="isActivityLoading">
            <div class="text-center text-info">
            <b-spinner class="align-middle" /> &nbsp;
            <span class="align-middle">Chargement... </span>
          </div>
        </div>

        <div
      v-else-if="timeline.length == 0"
      class="d-flex flex-column justify-content-center mt-5 text-center"
      style="padding-top: 30vh"
    >
      <h1 class="text-warning">
        <feather-icon icon="ActivityIcon" size="100" />
      </h1>
      <p class="font-medium-1 text-warning font-weight-normal">
        Aucune action effectuée
      </p>
    </div>
      
        <ul class="timeline" v-else>
            <li class="timeline-item" v-for="item in timeline" v-if="item.data.name !== null ">
                <b-avatar size="50" variant="primary" :text="
            avatarText(
              `${item.user.full_name} ` 
            )
          " class="timeline-badge" />

                <div class="timeline-panel">
                    <div class="timeline-heading">
                        <h4 class="timeline-title">{{ item.data.name }}</h4>
                        <div class="timeline-panel-controls">
                            <div class="controls">

                            </div>
                            <div class="timestamp">
                                <span class="text-info">{{ moment(item.created_at).local('fr').format('llll') }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="timeline-body" v-if="item.data.name == 'Archivage'">
                        <p>{{ item.user.full_name }} a archivé la commande {{ item.data.params.recurringOrder.id }} </p>
                    </div>
                </div>
            </li>
          
        </ul>
    </div>

    <div v-else>
        <div v-if="isActivityLoading">
            <div class="text-center text-info">
            <b-spinner class="align-middle" /> &nbsp;
            <span class="align-middle">Chargement... </span>
          </div>
        </div>

        <div
      v-else-if="timeline.length == 0"
      class="d-flex flex-column justify-content-center mt-5 text-center"
      style="padding-top: 30vh"
    >
      <h1 class="text-warning">
        <feather-icon icon="ActivityIcon" size="100" />
      </h1>
      <p class="font-medium-1 text-warning font-weight-normal">
        Aucune action effectuée
      </p>
    </div>
        <ul class="timeline" v-else>
            <li class="timeline-item" v-for="item in timeline" v-if="item.data.name !== null ">
                <b-avatar size="50" variant="primary" :text="
                avatarText(
                `${item.user.full_name} ` 
                )
          " class="timeline-badge" />
                <div class="timeline-panel">
                    <div class="timeline-heading">
                        <h4 class="timeline-title">{{ item.data.name }}</h4>
                        <div class="timeline-panel-controls">
                            <div class="controls">

                            </div>
                            <div class="timestamp">
                                <span class="text-info">{{ moment(item.created_at).local('fr').format('llll') }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="timeline-body" v-if="item.data.name == 'Archivage'">
                        <p> Vous avez archivé la commande {{ item.data.params.recurringOrder.id }}</p>
                    </div>
                </div>
            </li>

        </ul>
    </div>
    <div class="d-flex justify-content-between mt-2 flex-wrap">
        <b-button :disabled="metaData.prev_page_url == null" variant="outline-secondary" @click="applyLoadMoreActivityAction(metaData.prev_page_url)">
            <span class="text-white text-nowrap font-medium-2">Page précédente</span>
        </b-button>

        <div>
            <span class="text-white font-medium-2">{{ metaData.current_page }} sur {{ metaData.last_page }} pages</span>
        </div>
        <div>
            <span class="text-white font-medium-2">Total : {{ total }}</span>
        </div>
        <b-button :disabled="metaData.next_page_url == null" variant="outline-secondary" @click="applyLoadMoreActivityAction(metaData.next_page_url)">
            <span class="text-nowrap font-medium-1 text-white">Page suivante</span>
        </b-button>
    </div>
</div>
</template>

<script>
import {
    avatarText
} from "@core/utils/filter";
import {
    BCard,
    BAvatar,
    BBadge,
    BButton
} from "bootstrap-vue";
import moment from "moment";
import {
    mapGetters,
    mapActions
} from "vuex";
export default {
    components: {
        BButton,
        BCard,
        BBadge,
        BAvatar,
    },
    data() {
        return {
            avatarText,
            timeline: [

            ],
        }
    },

    computed: {
        ...mapGetters('orders', ['getActivity']),
        ...mapGetters("auth", ["getUser"]),
        ...mapGetters(['isActivityLoading']),

        metaData() {
            const meta = {
                prev_page_url: "",
                next_page_url: "",
                current_page: "",
                current_page_url: "",
                last_page: "",
                total: "",
            };
            if (this.getActivity != null) {
                meta.prev_page_url = this.getActivity.prev_page_url;
                meta.next_page_url = this.getActivity.next_page_url;
                meta.current_page = this.getActivity.current_page;
                meta.last_page = this.getActivity.last_page;
                this.total = this.getActivity.total;
            }
            return meta;
        },
    },

    watch: {
      getActivity(newValue, oldValue) {
        if (oldValue.length !== 0 && oldValue.length !== newValue.length) {
          this.timeline = this.getActivity.data;
        }
      },

      isActivityLoading(val) {
      if (val === false) {
        this.timeline = this.getActivity.data;
      }
    },
      
    },

    created() {
      console.log(this.getActivity.data)
      if(this.getActivity.data.length !== 0){
        this.timeline = this.getActivity.data

      }
      else{
        this.applyGetActivityAction()
      }
    
    },

    methods: {
        ...mapActions('orders', ['getActivityAction', 'loadMoreActivityAction']),

        applyGetActivityAction() {
            this.getActivityAction()
                .then(response => {
                    this.timeline = this.getActivity.data
                    console.log("ok", this.timeline)
                })
                .catch(error => {
                    console.log(error)
                })
        },

        applyLoadMoreActivityAction(url) {
          this.$store.commit("SET_IS_ACTIVITY_LOADING", true);
          this.timeline = [];
          this.loadMoreActivityAction(url)
            .then(() => {
              this.$store.commit("SET_IS_ACTIVITY_LOADING", false);
            })
            .catch(() => {
              this.$store.commit("SET_IS_ACTIVITY_LOADING", false);
            });
    },
    }
}
</script>

<style>
#timeline-header {
    font-size: 26px;
}

.timeline {
    list-style: none;
    padding: 20px 0 20px;
    position: relative;
}

.timeline:before {
    background-color: #eee;
    bottom: 0;
    content: " ";
    left: 50px;
    margin-left: -1.5px;
    position: absolute;
    top: 0;
    width: 3px;
}

.timeline>li {
    margin-bottom: 20px;
    position: relative;
}

.timeline>li:before,
.timeline>li:after {
    content: " ";
    display: table;
}

.timeline>li:after {
    clear: both;
}

.timeline>li>.timeline-panel {
    border-radius: 10px;
    border: 0.2px solid #d4d4d4;
    box-shadow: 0 2px 2px rgba(239, 237, 237, 0.2);
    margin-left: 80px;
    padding: 20px;
    position: relative;
}

.timeline>li>.timeline-panel .timeline-heading .timeline-panel-controls {
    position: absolute;
    right: 8px;
    top: 5px;
}

.timeline>li>.timeline-panel .timeline-heading .timeline-panel-controls .timestamp {
    display: inline-block;
}

.timeline>li>.timeline-panel .timeline-heading .timeline-panel-controls .controls {
    display: inline-block;
    padding-right: 5px;
    border-right: 1px solid #aaa;
}

.timeline>li>.timeline-panel .timeline-heading .timeline-panel-controls .controls a {
    color: #999;
    font-size: 11px;
    padding: 0 5px;
}

.timeline>li>.timeline-panel .timeline-heading .timeline-panel-controls .controls a:hover {
    color: #333;
    text-decoration: none;
    cursor: pointer;
}

.timeline>li>.timeline-panel .timeline-heading .timeline-panel-controls .controls+.timestamp {
    padding-left: 5px;
}

.timeline>li .timeline-badge {
    background-color: rgb(38, 17, 172);
    border-radius: 50%;
    color: #fff;
    font-size: 1.4em;
    height: 50px;
    left: 50px;
    line-height: 52px;
    margin-left: -25px;
    position: absolute;
    text-align: center;
    top: 16px;
    width: 50px;
    z-index: 10;
}

.timeline>li .timeline-badge+.timeline-panel:before {
    border-bottom: 15px solid transparent;

    border-right: 15px solid #ccc;
    border-top: 15px solid transparent;
    content: " ";
    display: inline-block;
    position: absolute;
    left: -15px;
    right: auto;
    top: 26px;
}

.timeline>li .timeline-badge+.timeline-panel:after {
    border-bottom: 14px solid transparent;

    border-right: 14px solid #fff;
    border-top: 14px solid transparent;
    content: " ";
    display: inline-block;
    position: absolute;
    left: -14px;
    right: auto;
    top: 27px;
}

.timeline-badge.primary {
    background-color: #2e6da4 !important;
}

.timeline-badge.success {
    background-color: #3f903f !important;
}

.timeline-badge.warning {
    background-color: #f0ad4e !important;
}

.timeline-badge.danger {
    background-color: #d9534f !important;
}

.timeline-badge.info {
    background-color: #5bc0de !important;
}

.timeline-title {
    margin-top: 0;
    color: inherit;
}

.timeline-body>p,
.timeline-body>ul {
    margin-bottom: 0;
}

.timeline-body>p+p {
    margin-top: 5px;
}

.copy {
    position: absolute;
    top: 5px;
    right: 5px;
    color: #aaa;
    font-size: 11px;
}

.copy>* {
    color: #444;
}
</style>
